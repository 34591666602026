<template>
  <div>
    <v-dialog
      scrollable
      :value="status"
      persistent
      max-width="75vw"
      transition="dialog-transition"
    >
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="px-2">
            <v-card-title class="mx-0 px-3">
              <v-layout mx-1 row wrap align-center align-content-center>
                <v-icon size="22" class="mr-4">lock</v-icon>Select permissions
                for the role
              </v-layout>
              <v-spacer></v-spacer>
              <v-icon @click="closeModal">close</v-icon>
            </v-card-title>
            <v-card-text style="overflow: scroll; max-height: 500px;">
              <v-text-field
                v-model="search"
                placeholder="Search"
              ></v-text-field>
              <span v-for="res in resources" :key="res.id">
                <tree-view
                  :showAddPermissionButton="true"
                  @permission-selected="handlePermissionSelected"
                  @permission-removed="handlePermissionRemoved"
                  @resource-selected="handleResourceSelected"
                  @resource-removed="handleResourceRemoved"
                  @add-new-permission="handeNewPermissionClicked"
                  :node="res"
                ></tree-view>
                <v-divider class="mx-1"></v-divider>
              </span>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="closeModal"
                elevation="0"
                color="primary"
                class="text-capitalize"
                >Done</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-slide-y-transition>
          <v-tab-item v-if="tab === 1">
            <v-card class="px-2">
              <v-card-title class="mx-0 px-3">
                <v-icon @click="tab = 0">arrow_left</v-icon>
                <v-layout mx-1 row wrap align-center align-content-center>
                  Creating new permission for
                  <span
                    class="ml-1 font-weight-black darkGrey--text text--lighten-3"
                    >{{
                      resourceSelected ? resourceSelected.display_name : ""
                    }}</span
                  >
                </v-layout>
              </v-card-title>
              <v-card-text>
                <v-form ref="custom-permission-form">
                  <v-row>
                    <v-col cols="12" md="12" class="py-0">
                      <v-text-field
                        v-if="
                          resourceSelected.custom_config.data_type === 'number'
                        "
                        :rules="validateCustomPropertyName"
                        v-model.number="customPermission.name"
                        type="number"
                        name="name"
                        label="Name"
                        id="id"
                      ></v-text-field>
                      <v-text-field
                        v-if="
                          resourceSelected.custom_config.data_type === 'string'
                        "
                        :rules="validateCustomPropertyName"
                        v-model="customPermission.name"
                        name="name"
                        label="Name"
                        id="id"
                      ></v-text-field>
                      <v-menu
                        ref="menu"
                        :close-on-content-click="false"
                        :return-value.sync="customPermission.name"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-if="
                              resourceSelected.custom_config.data_type ===
                              'date'
                            "
                            v-model="customPermission.name"
                            label="Name"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customPermission.name"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(customPermission.name)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="menu"
                        :close-on-content-click="false"
                        :return-value.sync="customPermission.name"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-if="
                              resourceSelected.custom_config.data_type ===
                              'time'
                            "
                            v-model="customPermission.name"
                            label="Name"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          use-seconds
                          v-model="customPermission.name"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(customPermission.name)"
                            >OK</v-btn
                          >
                        </v-time-picker>
                      </v-menu>
                      <v-menu
                        ref="datetime_date"
                        :close-on-content-click="false"
                        :return-value.sync="customPermission.name"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-if="
                              resourceSelected.custom_config.data_type ===
                              'datetime'
                            "
                            v-model="datetime_date"
                            label="Name - Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datetime_date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.datetime_date.save(datetime_date)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="datetime_time"
                        :close-on-content-click="false"
                        :return-value.sync="customPermission.name"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-if="
                              resourceSelected.custom_config.data_type ===
                              'datetime'
                            "
                            v-model="datetime_time"
                            label="Name - Time"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          use-seconds
                          v-model="datetime_time"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.datetime_time.save(datetime_time)"
                            >OK</v-btn
                          >
                        </v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="12" class="py-0">
                      <v-text-field
                        :rules="[required('Name')]"
                        v-model="customPermission.display_name"
                        name="Display Name"
                        label="Display Name"
                        id="id"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="py-0">
                      <v-text-field
                        :rules="[required('Description')]"
                        v-model="customPermission.description"
                        name="Description"
                        label="Descriptiom"
                        id="id"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="tab = 0"
                  text
                  elevation="0"
                  color="darkGrey"
                  class="text-capitalize"
                  >Cancel</v-btn
                >
                <v-btn
                  @click="addNewCustomPermission"
                  elevation="0"
                  color="primary"
                  class="text-capitalize"
                  >Create</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-slide-y-transition>
      </v-tabs-items>
    </v-dialog>
  </div>
</template>

<script>
import TreeView from '@/components/custom-components/TreeView'
import { RolesAPI } from '../../clients/permissions/roles'
import { minValue, maxValue, required } from '@/util/validations'
import _ from 'lodash'

export default {
  data() {
    return {
      search: '',
      tab: 0,
      resourceSelected: null,
      customPermission: {
        app_resource_id: '',
        name: '',
        display_name: '',
        description: ''
      },
      datetime_date: '',
      datetime_time: ''
    }
  },
  components: {
    TreeView
  },
  props: {
    status: {
      type: Boolean,
      default: false
    },
    resources: {
      type: Array,
      required: true
    },
    role: {
      type: Object,
      required: true
    }
  },
  computed: {
    _resources() {
      return _.clone(this.resources)
    },
    filteredResources() {
      if (this.search) {
        return this._resources
          .filter(el => {
            return el.permissions.some(child => {
              return child.display_name
                .toLowerCase()
                .match(this.search.toLowerCase())
            })
          })
          .map(el => {
            let newElt = Object.assign({}, el) // copies element
            return {
              ...newElt,
              permissions: newElt.permissions.filter(permission => {
                return permission.display_name
                  .toLowerCase()
                  .match(this.search.toLowerCase())
              })
            }
          })
      }
      return this._resources
    },
    validateCustomPropertyName() {
      if (this.resourceSelected) {
        if (this.resourceSelected.data_type === 'number') {
          return [this.minValue('Name', this.resourceSelected.custom_config.min_value), this.maxValue('Name', this.resourceSelected.custom_config.max_value)]
        } else {
          return [this.required('Name')]
        }
      } else {
        return [this.required('Name')]
      }
    }
  },
  watch: {},
  methods: {
    minValue,
    maxValue,
    required,
    closeModal() {
      this.$emit('close-modal')
    },
    confirmPermissions() { },
    async handlePermissionSelected({ perm }) {
      let data = {
        permission_id: perm.id
      }
      try {
        await RolesAPI.addPermissionToRole(this.role.id, data)
      } catch (error) {
        console.log(error)
      }
    },
    async handlePermissionRemoved({ perm }) {
      let data = {
        permission_ids: [perm.id]
      }
      try {
        await RolesAPI.removePermissionsFromARole(this.role.id, data)
      } catch (error) {
        console.log(error)
      }
    },
    async handleResourceSelected({ resource }) {
      let data = {
        permission_ids: this.getAllPermissionId(resource)
      }
      try {
        await RolesAPI.addManyPermissionToRole(this.role.id, data)
      } catch (error) {
        console.log(error)
      }
    },
    async handleResourceRemoved({ resource }) {
      let data = {
        permission_ids: this.getAllPermissionId(resource)
      }
      try {
        await RolesAPI.removePermissionsFromARole(this.role.id, data)
      } catch (error) {
        console.log(error)
      }
    },
    getAllPermissionId(resource) {
      let data = []
      data.push(...resource.permissions.map(i => i.id))
      resource.children.forEach(i => {
        data.push(...this.getAllPermissionId(i))
      })
      return data
    },
    async handeNewPermissionClicked({ node }) {
      this.resourceSelected = node
      await this.$nextTick()
      await this.$nextTick()
      this.tab = 1
    },
    async addNewCustomPermission() {
      if (!this.$refs['custom-permission-form'].validate()) {
        return
      }
      let response
      let data = JSON.parse(JSON.stringify(this.customPermission))
      data.app_resource_id = this.resourceSelected.id
      if (this.resourceSelected.custom_config.data_type === 'datetime') {
        data.name = this.datetime_date + 'T' + this.datetime_time + 'Z'
      }
      try {
        response = await RolesAPI.addCustomPermissionToRoleResource(this.role.id, data)
      } catch (error) {
        return console.log(error)
      }
      this.customPermission = {
        app_resource_id: '',
        name: '',
        display_name: '',
        description: ''
      }
      this.tab = 0
      this.resourceSelected = null
      this.$emit('fetch-new-resources-and-permissions')
      this.$refs['custom-permission-form'].reset()
      console.log(response)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-treeview-node__root {
  padding: 0 !important;
}
</style>
