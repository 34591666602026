
const strongPasswordRegex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
export const required = (field) => v => !!v || `${field} is required.`
export const email = () => v => /.+@.+/.test(v) || 'E-mail must be valid.'
export const minLength = (field, length) => v => v.length <= length || `${field} must be less than ${length} characters`
export const maxLength = (field, length) => v => v.length >= length || `${field} must be less than ${length} characters`
export const strongPassword = () => v => strongPasswordRegex.test(v) || 'Password must be 8 characters long, contain 1 upper , 1 lower and 1 special character.'
export const confirmPassword = (pass1, pass2) => v => pass1 === pass2 || 'Passwords do not match'
export const minValue = (field, val) => v => v > val || `${field} must be greater than ${val}.`
export const maxValue = (field, val) => v => v < val || `${field} must be lesser than ${val}.`
export const minCurrency = (field, val) => v => Number(v.replace(/[^0-9.-]+/g, '')) > val || `${field} must be greater than ${val}.`
export const maxCurrency = (field, val) => v => Number(v.replace(/[^0-9.-]+/g, '')) < val || `${field} must be lesser than ${val}.`
export const divisibleBy10 = (field) => v => Number(v.replace(/[^0-9.-]+/g, '')) % 10 === 0 || `${field} must be placed in terms of 10s, 20s, 30s etc.`
export const Validations = {
  required,
  email,
  minLength,
  maxLength,
  strongPassword,
  confirmPassword,
  minValue
}
// TODO: Make VUEX like mapValidators to get only the required
